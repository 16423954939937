<template>
  <card
    :content="content"
    :schema="getSchemaRequestType"
    :form-data="getDataRequestType"
    :editable="getEditableRequestType"
    @update-form="updateRequestType"
    @submitAction="addRequestType"
  />
</template>

<script>
/* eslint-disable no-console */
import Card from "../components/Card";
import confRequestType from "../conf/confRequestType.json";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RequestType",
  components: { Card },
  title() {
    return this.$t(this.$route.meta.title);
  },

  data() {
    return {
      hidden: true,
      content: confRequestType.content
    };
  },
  beforeCreate() {
    this.$store.dispatch("requestType/loadRequestType");
  },
  computed: {
    ...mapGetters("requestType", [
      "getDataRequestType",
      "getSchemaRequestType",
      "getEditableRequestType"
    ])
  },
  methods: {
    ...mapActions("requestType", [
      "updateRequestType",
      "addRequestType",
      "loadRequestType"
    ])
  }
};
</script>

<style scoped></style>
